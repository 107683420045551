import React, { Component } from 'react';
import Header from "./header.js";
import Footer from "./footer.js";

// FontAwesome Icons Library;
import { library } from '@fortawesome/fontawesome-svg-core';
import { faInstagram, faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

library.add(faInstagram, faTwitter, faLinkedin, faEnvelope);

class Layout extends Component {
    constructor(props) {
        super(props);

        this.state = {
            primaryColor: null,
            secondaryColor: "#212121",
            clientLoad: false
        }

        this.colorPicker = this.colorPicker.bind(this);
    }

    componentDidMount() {
        this.setState({
            primaryColor: this.colorPicker(),
            clientLoad: true
        })
    }

    colorPicker() {
        const colors = ["#0A75D1", "#F23D5E", "#9373D9", "#04BF68", "#8B2B8C"];
        let random = Math.floor(Math.random() * (colors.length));

        return colors[random];
    }

    render() {

        if (!this.state.clientLoad) {
            return (
                <div>
                    {this.props.children[0]}
                </div>
            )
        }

        return (
            <>
                <Header backgroundColor={this.state.primaryColor} />
                <main>
                    {this.props.children[0]}
                    {React.cloneElement(this.props.children[1], { mainColor: this.state.primaryColor, secondaryColor: this.state.secondaryColor })}
                </main>
                <Footer backgroundColor={this.state.primaryColor} />
            </>
        )
    } 
}

export default Layout;
