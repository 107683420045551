import React, {useState, useEffect} from "react";
import { Link } from "gatsby";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Logo from "../../static/images/logo.png";

function Header(props) {
    const [bgColor, setBgColor] = useState(props.backgroundColor);

    useEffect(() => {
        setBgColor(props.backgroundColor);
    }, [props.backgroundColor])

    return (
        <header className="navbar navbar-light navbar-expand-md justify-content-center" style={{backgroundColor: bgColor, zIndex: "2", minHeight: "75px"}}>
            <div className="d-block-header d-md-none">
                <Link to="/">
                    <div className="w-100 justify-content-center">
                        <span className="ps-4 navbar-brand header-logo" style={{color: "white"}}>Kat Devs</span>
                    </div>
                </Link>
                <div className="justify-content-end">
                    <button 
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarpog"
                        aria-controls="navbarpog"
                        aria-expanded="false"
                        aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                </div>
                <div className="collapse navbar-collapse" id="navbarpog">
                    <div className="container-fluid">
                        <ul className="mt-3 navbar-nav">
                            <li className="nav-item">
                                <Link to="/quem-somos/">
                                    <span className="nav-link header-labels text-center">Quem Somos</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/contatos/">
                                    <span className="nav-link header-labels text-center">Entre em Contato</span>
                                </Link>
                            </li>
                            <li className="mt-2 nav-item text-center">
                                <a href="https://twitter.com/katdevs" target="_blank" rel="noopener noreferrer">
                                    <FontAwesomeIcon icon={["fab", "twitter"]} color="whitesmoke" size="2x"/>                    
                                </a>
                                <a className="ps-3 pe-3" href="https://www.instagram.com/katdevs" target="_blank" rel="noopener noreferrer">
                                    <FontAwesomeIcon icon={["fab", "instagram"]} color="whitesmoke" size="2x"/>
                                </a>
                                <a href="https://www.linkedin.com/company/katdevs" target="_blank" rel="noopener noreferrer">
                                    <FontAwesomeIcon icon={["fab", "linkedin"]} color="whitesmoke" size="2x"/>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="d-none d-md-block-header">
                <ul className="nav navbar-nav w-100 justify-content-start">
                    <Link to="/">
                        <span className="ps-4 navbar-brand h1 header-logo" style={{color: "white"}}>Kat Devs</span>
                    </Link>
                </ul>
                <ul className="navbar-nav w-100 justify-content-center">
                    <li className="nav-item">
                        <Link to="/quem-somos/">
                            <span className="nav-link header-labels">Quem Somos</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/contatos/">
                            <span className="nav-link header-labels" href="#">Entre em Contato</span>
                        </Link>
                    </li>
                </ul>
                <ul className="pe-4 nav navbar-nav ml-auto w-100 justify-content-end">
                    <li className="nav-item">
                        <a href="https://twitter.com/katdevs" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={["fab", "twitter"]} color="whitesmoke" size="2x"/>                    
                        </a>
                    </li>
                    <li className="ps-2 pe-2 nav-item">
                        <a href="https://www.instagram.com/katdevs" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={["fab", "instagram"]} color="whitesmoke" size="2x"/>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a href="https://www.linkedin.com/company/katdevs" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={["fab", "linkedin"]} color="whitesmoke" size="2x"/>
                        </a>
                    </li>
                </ul>
            </div>
        </header>
    )
}


export default Header;